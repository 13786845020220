.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.containerInner {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 100%;
  align-self: center;
  max-width: 600px;
  padding: 60px 15px 30px 15px;
}

.containerLoading {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
  align-content: center;
  padding: 160px 0 0 0;
}

.column {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.logoContainer img {
  width: auto;
  height: auto;
  align-self: center;
  max-height: 100%;
  max-width: 100%;
  user-select: none;
}

.error {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 18px 0;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #FD5750;
  font-size: 18px;
}

.success {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 32px 0;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #78f542;
  font-size: 24px;
}

.containerRegister {}

.containerSignIn {}

.socialButtons {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 15px auto 0px auto;
  width: 100%;
}

.buttonGithub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  margin: 0 auto 10px auto;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  font-size: 19px;
  font-weight: 500;
  opacity: 0.6;
  transition: all 0.3s ease;
  text-transform: lowercase;
}

.buttonGithub:hover {
  cursor: pointer;
  opacity: 1;
}

.buttonGithub img {
  max-height: 28px;
  margin-right: 13px;
  margin-top: 1.5px;
}

.buttonGoogle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  font-size: 19px;
  font-weight: 500;
  opacity: 0.6;
  transition: all 0.3s ease;
  text-transform: lowercase;
}

.buttonGoogle:hover {
  cursor: pointer;
  opacity: 1;
}

.buttonGoogle img {
  max-height: 25px;
  margin-right: 11px;
  margin-top: 3px;
}

.formType {
  display: flex;
  flex-direction: row;
  margin: 0px auto 0px auto;
  width: 100%;
  padding: 10px 0;
  font-size: 22px;
  color: rgba(255,255,255,0.6);
  text-transform: lowercase;
  user-select: none;
}

.formTypeRegister {
  display: flex;
  width: 50%;
  padding: 5px 40px 5px 0;
  justify-content: flex-end;
  border-right: 2px solid rgba(255,255,255,0.15);
}

.formTypeRegister:hover {
  cursor: pointer;
}

.formTypeSignIn {
  display: flex;
  width: 50%;
  padding: 5px 0 5px 40px;
  text-align: left;
}

.formTypeSignIn:hover {
  cursor: pointer;
}

.formTypeActive {
  color: rgba(255,255,255,1);
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
  margin: 10px auto 0 auto;
  text-align: center;
}

.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
}


.formLabel {
  font-size: 19px;
  font-weight: 500;
  margin: 0 0 10px 0;
  user-select: none;
  display: none;
}

.formInput {
  text-align: center;
  font-size: 22px;
  padding: 4px 0px 16px 0px;
  border-bottom: 1.5px solid rgba(255,255,255,0.4);
  border-top: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  border-left: 1.5px solid transparent;
  transition: all 0.6s ease;
  background: none;
  outline: none;
  color: #ffffff;
  caret-color: white;
}

.formInput::placeholder {
  text-align: center;
  font-size: 18px;
  color: rgba(255,255,255,0.5);
  margin: 0;
}

.formInput:focus {
  border-bottom: 1.5px solid rgba(255,255,255,1);
}

.formError {
  margin: 10px 0 10px 0;
  color: #FD5750;
  font-size: 18px;
  line-height: 32px;
}

.formButton {
  margin: 16px auto 0 auto!important;
}

.formButton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.formButton:active {
  cursor: pointer;
  transform: scale(1);
}

.forgotPassword {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 20px auto 5px auto;
  width: 100%;
  padding: 10px 0;
  font-size: 19px;
  color: rgba(255,255,255,0.6);
  transition: all 0.3s ease;
}

.forgotPassword:hover {
  cursor: pointer;
  color: rgba(255,255,255,1);
}